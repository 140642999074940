import { genMuiThemes } from 'clnt-common';

const ltp    = '#fff9f9';    // light color with red tint - nav bar bg color
const lts    = '#9c27b0';    // button bg in light mode (darker purple) - secondary / also used in the manifest.webmanifest
const ltpnl  = '#fff9f9';    // light color with darker red tint for panel - 22 biggest... section bg color
const ltcpnl = '#fff9ff';    // light content panel -  (The same is used in manifest.webmanifest)

const dkp    = '#0e0e0e';    // '#121212',
const dks    = '#a257af';    // button bg in dark mode (lighter purple) - secondary
const dkpnl  = '#0e0e0e';    // compliment to ltpnl
const dkcpnl = '#0e0e00';    // dark content panel

const options = {
  fontHeader :      'Albra',
  fontText :        'Poppins',

  primaryLight:     ltp,   //'#fdeded', //'#ededed',
  secondaryLight:   dks,   //'#9c27b0',
  panelBgLight:     ltpnl,   //'#ffffff',
  backpanelBgLight: ltp,   //'#484848',
  contentpanelBgLight: ltcpnl,

  primaryDark:      dkp,   //'#121212',
  secondaryDark:    lts,   //'#ce93d8',
  panelBgDark:      dkpnl,   //'#0e0e0e',
  backpanelBgDark:  dkp,   //'#cfcfcf',
  contentpanelBgDark:  dkcpnl,

  pagePadding : 1,
  pageGap : 0,

  // For use within pages.ts (rendered via PageContentFormat.jsx)
  // 5 sets of areas.
  areaLight: [{ panel: '', border: '#cccccc' },  // about
              { panel: '', border: '#3845f3' },  // service:matrix
              { panel: '#e8eafb', border: '' },  // service:chat
              { panel: '#f6f1f1', border: '#ff0000' },  // service: *
              { panel: '', border: '' }],  
  areaDark:  [{ panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' }]  
};

export { themes };
let themes = genMuiThemes(options);
