//
// Following are the all available pages for this site.
// They are listed in the NAVBAR ORDER and FOOTER ORDER
// NOTE: We are lazy loading the base React component.
// 
const routes: Route[] = [
  // Navbar      
  { path: "/",                    import: () => import("./pages/Home") },
  { path: "/products",            import: () => import("./pages/Products") },
  { path: "/benefits",            import: () => import("./pages/Benefits") },
  { path: "/myaccount",           import: () => import("./pages/MyAccount") , module: "./pages/MyAccount"},

  // Footer 
  { path: "/privacy",             import: () => import("./pages/privacy.mdx") },
  { path: "/termsandconditions",  import: () => import("./pages/termsandconditions.mdx") },

  // Draft
  { path: "/about",               import: () => import("./pages/About") },
  { path: "/pricing",             import: () => import("./pages/Pricing") },
  { path: "/getstarted",          import: () => import("./pages/GetStarted") },
  { path: "/mycrudli",            import: () => import("./pages/MyCrudli") },

  { path: "/blog",                import: () => import("./pages/blog/BlogHome") },
  { path: "/blog/:name",          import: () => import("./pages/blog/BlogTmpl") },

  { path: "*",                    import: () => import("./pages/FourOFour") },
];

export default routes;